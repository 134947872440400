import React, { FC, useEffect, useState } from 'react';
import { Variant } from 'shared/types/product';
import { Category } from 'shared/types/product/Category';
import Breadcrumb from 'components/commercetools-ui/atoms/breadcrumb';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import Gallery from 'components/commercetools-ui/organisms/gallery';
import ShippingSection from 'components/commercetools-ui/organisms/product/product-details/components/shipping-section';
import useTrack from 'components/commercetools-ui/organisms/product/product-details/hooks/useTrack';
import { UIProduct } from 'components/commercetools-ui/organisms/product/product-details/types';
import { useAddToCartOverlay } from 'context/add-to-cart-overlay';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import { ProjectConfig } from 'types/project-config';
import { useProduct } from 'frontastic';
// import { PDP_PRODUCT_CONTACT_DIVESHOP } from 'helpers/constants/events';

export interface ProductDetailsCoursesNonPurchasableProps {
  product: UIProduct;
  variant: Variant;
  url?: string;
  onChangeVariant: (sku: string) => void;
  inModalVersion?: boolean;
  setIsOpen?: (value: boolean) => void;
  onAddToCart?: () => void;
  projectConfig: ProjectConfig;
}

const ProductDetailsCoursesNonPurchasable: FC<ProductDetailsCoursesNonPurchasableProps> = ({
  product,
  variant,
  url,
  inModalVersion,
  setIsOpen,
  projectConfig,
}) => {
  const { formatMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatMessagePadi } = useFormat({ name: 'padi' });

  const [isDesktopSize] = useMediaQuery(desktop);
  const [category, setCategory] = useState<Category>();
  const [productKey, setProductKey] = useState<string>();

  const { categories } = useProduct();

  useEffect(() => {
    if (product) {
      setProductKey(product?.productKey);
      if (categories && !category) {
        const currentCategory = categories.find((cat) => cat.categoryId === product.categories?.[0]?.categoryId);
        setCategory(currentCategory);
      }
    }
  }, [categories, category, product, product?.categories]);

  // const { trackContactDiveshop } = useTrack({ product, inModalVersion });

  const { fetchRelatedProducts } = useAddToCartOverlay();

  useEffect(() => {
    fetchRelatedProducts(product);
  }, [product, fetchRelatedProducts]);

  const wrapperClassName = inModalVersion
    ? 'md:grid grid-cols-12 pt-70 pb-35 px-20 md:pr-36'
    : 'pt-20 md:pt-24 pb-32 md:grid md:grid-cols-12 md:items-start mx-auto max-w-7xl px-24 lg:px-32';

  const galleryContainerClassName = useClassNames([
    inModalVersion ? 'col-span-6' : 'md:col-span-7 lg:col-span-8',
    'md:pr-26 lg:pr-60 h-auto min-h-200',
  ]);

  const informationContainerClassName = useClassNames([
    inModalVersion ? 'col-span-6' : 'md:col-span-5 lg:col-span-4',
    'mt-24 md:mt-0 h-auto min-h-150',
  ]);

  const titleClassName = useClassNames(['break-normal mb-12', { '2xl:text-18': !inModalVersion }]);

  const padiDomains = projectConfig?.padiDomains;
  const [wwwSiteUrl, setWwwSiteUrl] = useState<string>('');
  useEffect(() => {
    setWwwSiteUrl(`${padiDomains?.www}` || '');
  }, [wwwSiteUrl]);

  const handleContactDiveShop = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // trackContactDiveshop();
    window.open(`${wwwSiteUrl}/dive-shops/nearby/?ctkey=${productKey}`, '_blank');
  };

  return (
    <div className={wrapperClassName}>
      {category && isDesktopSize && !inModalVersion && (
        <Breadcrumb Separator="/" className="col-span-12 mb-24 w-fit">
          <Link key={category.categoryId} link={category._url} className="text-14 text-primary-black">
            {category.name}
          </Link>

          <Typography key={product.slug} className="cursor-default text-14 text-neutral-500">
            {product.name}
          </Typography>
        </Breadcrumb>
      )}

      <div className={galleryContainerClassName}>
        <Gallery
          images={variant?.images ?? []}
          imagesExtra={variant?.imagesExtra ?? []}
          inModalVersion={inModalVersion}
          firstImagePriority={true}
          hideArrowsOnSingleSlide={true}
        />
      </div>

      <div className={informationContainerClassName}>
        <div className="relative flex pr-40">
          <Typography as="h1" className={`text-xl font-bold leading-loose md:text-3xl`}>
            {product?.name}
          </Typography>
        </div>

        <div className="flex gap-8 pt-20">
          <Button
            className="w-full rounded-sm text-14 font-medium uppercase"
            variant="primary"
            onClick={handleContactDiveShop}
            // dataAnalytics={PDP_PRODUCT_CONTACT_DIVESHOP}
          >
            {formatMessagePadi({ id: 'contactDiveShop', defaultMessage: 'Contact Dive Shop' })}
          </Button>
        </div>

        {/* {!inModalVersion && <ShippingSection />} */}

        {inModalVersion && (
          <Link
            link={url}
            className="mx-auto mt-28 block w-fit border-b border-transparent text-center text-14 leading-loose text-secondary-black hover:border-secondary-black"
            onClick={() => setIsOpen?.(false)}
          >
            {formatMessage({ id: 'more.details', defaultMessage: 'More details' })}
          </Link>
        )}
      </div>
    </div>
  );
};

export default ProductDetailsCoursesNonPurchasable;
